<template>
    <div class="page-domains-domain-list container">
        <div class="row">
            <div class="col-12 heading-box">
                <h1 class="heading">Domain List</h1>

                <div class="dropdown-select-box">
                    <span>See:</span>

                    <app-dropdown-select
                        v-model="pagination.limit"
                        :options="pagination_limit_options"

                        key-value="value"
                        key-title="title"

                        @change="onChangePaginationLimit"
                    />
                </div>
            </div>

            <div class="col-12">
                <app-error v-model="errors.show" :message="errors.message"></app-error>
            </div>

            <div class="col-12">
                <app-search-box
                    label="Enter your domain name"

                    @search="onSearch"
                />
            </div>

            <div class="col-12">
                <app-table
                    class="domain-list-table"

                    v-model="selected"

                    :sort-value="filter.sort"
                    :sort-options="sort"
                    @sort-change="onSortChange"

                    :clickable="true"

                    :cols="cols"
                    :rows="rows"

                    :loading="loading"

                    @row-click="onDomainDetails"
                    @row-click-mobile="onDomainDetails"
                >
                    <template #sub-header-actions v-if="show_table_actions">
                        <button class="btn btn-table-action action-secondary action-refresh"      @click="renew"          :disabled="loading"><span>Renew</span></button>
                        <button class="btn btn-table-action action-secondary action-edit"        @click="setNameservers" :disabled="loading"><span>Set Nameservers</span></button>
                    </template>
                </app-table>

                <app-pagination
                    v-if="pagination.total"

                    v-model="pagination.page"

                    :total="pagination.total"
                    :limit="pagination.limit"
                    :page-range="pagination.range"

                    @change="onPageChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appDropdownSelect from '@/components/app-dropdown-select'
import appSearchBox from '@/components/app-search-box'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

const DOMAIN_LIST = [
    {
        UUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899df',
        DomainName: 'domain.name.com',
        RegistrantName: 'Evangelina Cooke',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'df0d2962-1c8e-498b-a146-29709b85fbd6',
        DomainName: 'domain.name.com',
        RegistrantName: 'Addie Chambers',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '990bd740-2008-4b79-896f-cbd2ea899858',
        DomainName: 'domain.name.com',
        RegistrantName: 'Janet Hubbard',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '7afa6a6c-4f42-472c-b77e-cc34cb0a61fa',
        DomainName: 'domain.name.com',
        RegistrantName: 'Colleen Conway',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'a41282a3-8fce-464f-9639-7462748ef7b2',
        DomainName: 'domain.name.com',
        RegistrantName: 'Cote Rodriguez',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'c8c4cfb6-1c66-4593-8199-72d2eb2ee624',
        DomainName: 'domain.name.com',
        RegistrantName: 'Landry Wolf',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'e82cfeef-9f8d-4c6b-a3b6-cbdf5cdcf3f9',
        DomainName: 'domain.name.com',
        RegistrantName: 'Elvia Whitaker',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '7ef77ec0-7f8a-404f-9d1d-35faacce9b53',
        DomainName: 'domain.name.com',
        RegistrantName: 'Gross Ratliff',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '56d4bf21-fd30-43ec-bf4d-ab2b044c9e8b',
        DomainName: 'domain.name.com',
        RegistrantName: 'Amalia Morton',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'f6e1af91-4298-4a0c-8902-a8af6b44ae3a',
        DomainName: 'domain.name.com',
        RegistrantName: 'Verna Hickman',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'dfc77137-8f6b-4946-8d4f-d7e88274314c',
        DomainName: 'domain.name.com',
        RegistrantName: 'Newton Sellers',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '54ccfe9b-2d55-4a5f-b10a-6a7487fb54b3',
        DomainName: 'domain.name.com',
        RegistrantName: 'Hattie Gilliam',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '6da9eeb5-285f-4deb-b5d5-bc188c9341c5',
        DomainName: 'domain.name.com',
        RegistrantName: 'Helen Solomon',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'e79e5d25-ff13-4860-9c57-c9f31b3220b8',
        DomainName: 'domain.name.com',
        RegistrantName: 'Rollins Page',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '7a61e431-5b25-4383-83cf-7122541ca961',
        DomainName: 'domain.name.com',
        RegistrantName: 'Fowler Vincent',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '51552fc3-115d-4966-a9f3-533be6d0c5f9',
        DomainName: 'domain.name.com',
        RegistrantName: 'Yolanda Keith',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'c2521560-346a-4a76-9f91-def728bf8fa8',
        DomainName: 'domain.name.com',
        RegistrantName: 'Meagan Johnston',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '61e5363b-315f-455f-afd3-04f7d13d1025',
        DomainName: 'domain.name.com',
        RegistrantName: 'Roslyn Frank',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '48172132-ca06-4e2b-94d3-e22c2489e4d5',
        DomainName: 'domain.name.com',
        RegistrantName: 'Monica Valdez',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'fddd5979-db6e-4367-8434-928732dd0856',
        DomainName: 'domain.name.com',
        RegistrantName: 'Burton Merritt',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '8a88a293-b847-4754-b72c-af98542a55c5',
        DomainName: 'domain.name.com',
        RegistrantName: 'Carrie Hodges',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '6877fe79-f2e0-48b4-b842-9f7e0bca34f5',
        DomainName: 'domain.name.com',
        RegistrantName: 'Giles Clay',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'ca98b1e0-7bff-4542-a3d7-ec68813d189e',
        DomainName: 'domain.name.com',
        RegistrantName: 'Luann Roberts',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '5ea0566f-66f1-496a-8f91-3b73663553e3',
        DomainName: 'domain.name.com',
        RegistrantName: 'Caroline Grant',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '04d41bc6-6199-45e5-837a-9538bc1349d2',
        DomainName: 'domain.name.com',
        RegistrantName: 'Aileen Leonard',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '7ef309e0-7c5c-4c18-bc72-60118fb98cd5',
        DomainName: 'domain.name.com',
        RegistrantName: 'Holt Osborn',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'ba16acc7-8968-4a2f-8a22-166840f67619',
        DomainName: 'domain.name.com',
        RegistrantName: 'Roxie Schroeder',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'bee2e949-da98-4c1c-b03e-453ada6786fb',
        DomainName: 'domain.name.com',
        RegistrantName: 'Celia Vance',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '33f6326f-226d-4c8a-a99c-42c0b28cb47d',
        DomainName: 'domain.name.com',
        RegistrantName: 'Claudine Bradley',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'babd30ff-8c70-4518-ad97-1e85ec023afd',
        DomainName: 'domain.name.com',
        RegistrantName: 'Nola Fowler',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '1f687542-782a-4ff1-a7ce-3ae3109aca03',
        DomainName: 'domain.name.com',
        RegistrantName: 'Casey Olson',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '3bb48d4c-445c-42e4-9135-4ed876ecabab',
        DomainName: 'domain.name.com',
        RegistrantName: 'Love Langley',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '1ea7142f-e6b1-4a5c-89dc-7c33d3913f22',
        DomainName: 'domain.name.com',
        RegistrantName: 'Hillary Mayer',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '3e84a978-1e07-4292-8683-dea6ecbf3cb4',
        DomainName: 'domain.name.com',
        RegistrantName: 'Weiss Hampton',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'f9977f7a-50cd-40a0-a51c-1fab77d2e085',
        DomainName: 'domain.name.com',
        RegistrantName: 'Virgie Hull',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'f16f5862-e03f-4d4e-8688-3024bc8aa8d3',
        DomainName: 'domain.name.com',
        RegistrantName: 'Garrison Rosales',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '0a2bb9b8-8475-4994-be05-dc9492dc7642',
        DomainName: 'domain.name.com',
        RegistrantName: 'Madden Vinson',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'bd899de7-2364-4ed7-b9bb-75791179d185',
        DomainName: 'domain.name.com',
        RegistrantName: 'Nell Norman',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '591d6aeb-9060-4b51-a4f6-0fa4ef261413',
        DomainName: 'domain.name.com',
        RegistrantName: 'Caldwell Cummings',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '2d6238e3-ff79-464c-9fd2-c7bc85621fa7',
        DomainName: 'domain.name.com',
        RegistrantName: 'Laurie Tillman',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '97eb5a1c-1864-489a-8f55-7289bf461cde',
        DomainName: 'domain.name.com',
        RegistrantName: 'Bishop Jacobson',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '8f8ec9c2-114f-4e4e-bc27-5869de7debe6',
        DomainName: 'domain.name.com',
        RegistrantName: 'Sophie Torres',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'd2542e6e-6bcc-416b-9f80-eaa6b6340eeb',
        DomainName: 'domain.name.com',
        RegistrantName: 'George Sanchez',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'fc82f014-08f3-4ec8-a1b4-d1569026d3b2',
        DomainName: 'domain.name.com',
        RegistrantName: 'Owen Cooley',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '15b9365b-c85c-4dc5-a489-5b32e7aa39ef',
        DomainName: 'domain.name.com',
        RegistrantName: 'Juanita Lowery',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '3ecffe92-e870-42d1-bf71-468872fd3c8b',
        DomainName: 'domain.name.com',
        RegistrantName: 'Mildred Burks',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'b95387d0-0dae-4aa2-887f-eb4c6cda5d80',
        DomainName: 'domain.name.com',
        RegistrantName: 'Mack Wise',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '442f9784-5c9a-4629-8510-8afcec35d104',
        DomainName: 'domain.name.com',
        RegistrantName: 'Cohen Mack',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '3fc2757b-c743-431d-8757-b7ebfbcbd913',
        DomainName: 'domain.name.com',
        RegistrantName: 'Beach Hurley',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '737cd227-8e4d-479f-acd4-8dce37e0135e',
        DomainName: 'domain.name.com',
        RegistrantName: 'Shelia Shaffer',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '73066c70-96af-4835-8187-e9b9f807488b',
        DomainName: 'domain.name.com',
        RegistrantName: 'Santiago Noble',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'd0bd3285-4bd4-4149-a520-958e7f275c3a',
        DomainName: 'domain.name.com',
        RegistrantName: 'Nicole Gibbs',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '59c2e13e-cb12-486f-9527-90b9d517d87f',
        DomainName: 'domain.name.com',
        RegistrantName: 'Karen Stanley',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'f9072a6f-62e3-423a-bfca-4a6f412b51dc',
        DomainName: 'domain.name.com',
        RegistrantName: 'Lillie Small',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '4adbcd4e-9d77-4f29-ba66-d0edab37bb52',
        DomainName: 'domain.name.com',
        RegistrantName: 'Faulkner Villarreal',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '938960e6-42bd-410d-ba86-f2538ec66bdc',
        DomainName: 'domain.name.com',
        RegistrantName: 'Cherie William',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '8bcea60a-9f18-4fd4-b3e6-ed0fe2e4f04e',
        DomainName: 'domain.name.com',
        RegistrantName: 'Gloria Daniels',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '51f49cc1-bbd4-422c-b41b-1be0b0ea8054',
        DomainName: 'domain.name.com',
        RegistrantName: 'Rhea Wilder',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'eccf45b4-dfa2-4c16-b5a0-0855e5c14c11',
        DomainName: 'domain.name.com',
        RegistrantName: 'Haley Dyer',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '776a641b-175e-48c1-ae7d-6ba2c27876ca',
        DomainName: 'domain.name.com',
        RegistrantName: 'Mcintyre Garner',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '50be0f1a-8349-45d6-be2e-aab4a66c5183',
        DomainName: 'domain.name.com',
        RegistrantName: 'Valerie Irwin',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '078a3f00-b943-4398-9524-1a1667ca4c09',
        DomainName: 'domain.name.com',
        RegistrantName: 'Sue Maxwell',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'c37b133e-b31c-4016-a0f3-909fd2d5d784',
        DomainName: 'domain.name.com',
        RegistrantName: 'Darla Sullivan',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'fc250aca-ef5e-442a-874a-7281efb7d2a7',
        DomainName: 'domain.name.com',
        RegistrantName: 'Peggy Diaz',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '32ea2fe8-6ad9-4103-aaba-3896dc965672',
        DomainName: 'domain.name.com',
        RegistrantName: 'Tamara Petty',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '02601f0e-f7f7-4033-88e5-718a56f69be2',
        DomainName: 'domain.name.com',
        RegistrantName: 'Sanford Watson',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '429d6ba0-9283-41f8-8e7b-f2db7a424861',
        DomainName: 'domain.name.com',
        RegistrantName: 'Ellen Campbell',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '4de90701-b015-4d80-9576-71f6a9f451ae',
        DomainName: 'domain.name.com',
        RegistrantName: 'Patterson Barry',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '0c1a726d-8049-46c2-890b-8c223092383e',
        DomainName: 'domain.name.com',
        RegistrantName: 'Candy Joseph',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '68be37b6-b462-42bb-846a-8a0ad65174ff',
        DomainName: 'domain.name.com',
        RegistrantName: 'Mccray Dixon',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'a8954b64-3a37-4bef-a564-07fda4e791b4',
        DomainName: 'domain.name.com',
        RegistrantName: 'Jocelyn Martinez',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'b7b2122b-ada9-4a83-87d9-7a90f59a984d',
        DomainName: 'domain.name.com',
        RegistrantName: 'Marta Solis',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: '234faa94-f1e4-4055-a8e2-3d1e2c93d0f5',
        DomainName: 'domain.name.com',
        RegistrantName: 'Brandi Ochoa',
        ExpiryDate: 1596009318012468000
    },
    {
        UUID: 'bf4c47df-a998-418d-a570-54aaec13c945',
        DomainName: 'domain.name.com',
        RegistrantName: 'Nadine Quinn',
        ExpiryDate: 1596009318012468000
    },
]

export default {
    components: {
        appError,
        appTable,
        appPagination,
        appDropdownSelect,
        appSearchBox,
    },

    data() {
        return {
            filter: {
                word: '',
                previous: '',

                sort: {
                    field: 'ExpiryDate',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'DomainName',     title: 'Domain name'     },
                { field: 'RegistrantName', title: 'Registrant name' },
                { field: 'ExpiryDate',     title: 'Expiry date'     },
            ],

            cols: [
                { key: 'DomainName',     title: 'Domain Name',     sort: { field: 'DomainName' },     type: 'checkbox', },
                { key: 'RegistrantName', title: 'Registrant Name', sort: { field: 'RegistrantName' },                   },
                { key: 'ExpiryDate',     title: 'Expiry Date',     sort: { field: 'ExpiryDate' },                       },
            ],

            domain_list: [],

            selected: {},

            pagination_limit_options: [
                {
                    value: 10,
                    title: '10',
                },
                {
                    value: 20,
                    title: '20',
                },
                {
                    value: 30,
                    title: '30',
                },
                {
                    value: 40,
                    title: '40',
                },
                {
                    value: 50,
                    title: '50',
                },
            ],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.getDomainList()
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getDomainList()
        },

        onPageChange(page) {
            this.getDomainList(page)
        },

        onChangePaginationLimit() {
            this.pagination.page = 1

            this.getDomainList()
        },

        onSearch(value) {
            const word = value.trim()

            this.filter.word = word

            let page = 1

            if (this.filter.word != this.filter.previous) {
                this.selected = {}
            } else {
                page = this.pagination.page
            }

            this.filter.previous = word

            this.getDomainList(page)
        },

        getDomainList(page) {
            const delay_in_ms = 1000

            this.loading = true

            setTimeout(() => {
                const list = []

                const start = ( ( page || this.pagination.page ) - 1 ) * this.pagination.limit
                const total = DOMAIN_LIST.length
                const end = start + this.pagination.limit < total ? start + this.pagination.limit : total

                for (let i = start; i < end; i++) {
                    list.push(DOMAIN_LIST[i])
                }

                this.domain_list = list

                this.pagination.page = page || this.pagination.page
                this.pagination.total = total

                if (this.$route.params.page != this.pagination.page
                    && !(
                        this.$route.params.page === undefined
                        && this.pagination.page === 1
                    )
                ) {
                    this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                }

                this.errors = {
                    show: false,
                    message: '',
                }

                this.loading = false
            }, delay_in_ms)
        },

        renew() {
            console.log('renew()')
        },

        setNameservers() {
            console.log('setNameservers()')
        },

        onDomainDetails(domain) {
            this.$router.push({ name: 'domain', params: { uuid: domain.uuid } })
        },
    },

    computed: {
        rows() {
            const rows = []

            for (let i = 0, len = this.domain_list.length; i < len; i++) {
                const domain = this.domain_list[i]

                rows.push({
                    uuid:           domain.UUID,
                    DomainName:     domain.DomainName,
                    RegistrantName: domain.RegistrantName,
                    ExpiryDate:     formatDateNano(domain.ExpiryDate, 'YYYY-MM-DD HH:mm:ss'),
                })
            }

            return rows
        },

        show_table_actions() {
            return Object.keys(this.selected).length > 0
        },
    },
}
</script>

<style lang="scss">
.page-domains-domain-list {
    padding-bottom: 80px;

    .heading-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 48px;

        .dropdown-select-box {
            display: flex;
            align-items: center;

            & > span {
                margin-right: 16px;

                font-size: 18px;
                color: var(--color-table-secondary);
            }

            .app-dropdown-select {
                width: 100px;
            }
        }

    }

    .app-error,
    .app-search-box {
        margin-bottom: 24px;
    }

    .domain-list-table {
        @include table-cols-width((250px, 300px, 150px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-domains-domain-list {
        .domain-list-table {
            @include table-cols-width((250px, 200px, 150px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-domains-domain-list {
        .heading-box {
            margin-bottom: 32px;

            .dropdown-select-box {
                & > span {
                    display: none;
                }
            }
        }

        .domain-list-table {
            @include table-cols-width-mobile((137px, 137px), true);
        }
    }
}
</style>